<template>
  <div class="container">
    <header-bar>
      <template #search>
        <div class="wxq_left_search">
          <el-input
            placeholder="请输入关键字"
            prefix-icon="el-icon-search"
            v-model="input2"
          >
          </el-input>
          <button class="btn btn_blue">搜索</button>
        </div>
      </template>
    </header-bar>
    考试审核
  </div>
</template>
<script>
import headerBar from '@/components/backStageComponent/layout/headerBar/headerBar'
export default {
  name: 'testCheck',
  components: { headerBar },
  data() {
    return {
      input2: ''
    }
  }
}
</script>
